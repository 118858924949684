//Merchant laundering constant 
export const ML_SUB_CATEGORY_LAZY_FILTER_INIT = 'ML_SUB_CATEGORY_LAZY_FILTER_INIT';
export const ML_SUB_CATEGORY_LAZY_FILTER_INIT_SUCCESS = 'ML_SUB_CATEGORY_LAZY_FILTER_INIT_SUCCESS';
export const ML_SUB_CATEGORY_LAZY_FILTER_INIT_ERROR = 'ML_SUB_CATEGORY_LAZY_FILTER_INIT_ERROR';
export const ML_SUB_CATEGORY_LAZY_FILTER_INIT_NEXT = 'ML_SUB_CATEGORY_LAZY_FILTER_INIT_NEXT';

export const ML_PUBLISHER_LAZY_FILTER_INIT = 'ML_PUBLISHER_LAZY_FILTER_INIT';
export const ML_PUBLISHER_LAZY_FILTER_INIT_SUCCESS = 'ML_PUBLISHER_LAZY_FILTER_INIT_SUCCESS';
export const ML_PUBLISHER_LAZY_FILTER_INIT_ERROR = 'ML_PUBLISHER_LAZY_FILTER_INIT_ERROR';
export const ML_PUBLISHER_LAZY_FILTER_INIT_NEXT = 'ML_PUBLISHER_LAZY_FILTER_INIT_NEXT';

export const ML_SUB_CHANNEL_LAZY_FILTER_INIT = 'ML_SUB_CHANNEL_LAZY_FILTER_INIT';
export const ML_SUB_CHANNEL_LAZY_FILTER_SUCCESS = 'ML_SUB_CHANNEL_LAZY_FILTER_SUCCESS';
export const ML_SUB_CHANNEL_LAZY_FILTER_ERROR = 'ML_SUB_CHANNEL_LAZY_FILTER_ERROR';
export const ML_SUB_CHANNEL_LAZY_FILTER_NEXT = 'ML_SUB_CHANNEL_LAZY_FILTER_NEXT';

//Investment Scan constant 
export const IS_SUB_CATEGORY_LAZY_FILTER_INIT = 'IS_SUB_CATEGORY_LAZY_FILTER_INIT';
export const IS_SUB_CATEGORY_LAZY_FILTER_INIT_SUCCESS = 'IS_SUB_CATEGORY_LAZY_FILTER_INIT_SUCCESS';
export const IS_SUB_CATEGORY_LAZY_FILTER_INIT_ERROR = 'IS_SUB_CATEGORY_LAZY_FILTER_INIT_ERROR';
export const IS_SUB_CATEGORY_LAZY_FILTER_INIT_NEXT = 'IS_SUB_CATEGORY_LAZY_FILTER_INIT_NEXT'
// ---------------------------------------------------------------------------------------
// LEA 
export const LEA_USERID_LAZY_FILTER_INIT = 'LEA_USERID_LAZY_FILTER_INIT';
export const LEA_USERID_LAZY_FILTER_INIT_SUCCESS = 'LEA_USERID_LAZY_FILTER_INIT_SUCCESS';
export const LEA_USERID_LAZY_FILTER_INIT_ERROR = 'LEA_USERID_LAZY_FILTER_INIT_ERROR';
export const LEA_USERID_LAZY_FILTER_INIT_NEXT = 'LEA_USERID_LAZY_FILTER_INIT_NEXT'


export const LEA_POSTEDDATE2_LAZY_FILTER_INIT = 'LEA_POSTEDDATE2_LAZY_FILTER_INIT';
export const LEA_POSTEDDATE2_LAZY_FILTER_INIT_SUCCESS = 'LEA_POSTEDDATE2_LAZY_FILTER_INIT_SUCCESS';
export const LEA_POSTEDDATE2_LAZY_FILTER_INIT_ERROR = 'LEA_POSTEDDATE2_LAZY_FILTER_INIT_ERROR';
export const LEA_POSTEDDATE2_LAZY_FILTER_INIT_NEXT = 'LEA_POSTEDDATE2_LAZY_FILTER_INIT_NEXT'

export const LEA_KEYWORD_LAZY_FILTER_INIT = 'LEA_KEYWORD_LAZY_FILTER_INIT';
export const LEA_KEYWORD_LAZY_FILTER_INIT_SUCCESS = 'LEA_KEYWORD_LAZY_FILTER_INIT_SUCCESS';
export const LEA_KEYWORD_LAZY_FILTER_INIT_ERROR = 'LEA_KEYWORD_LAZY_FILTER_INIT_ERROR';
export const LEA_KEYWORD_LAZY_FILTER_INIT_NEXT = 'LEA_KEYWORD_LAZY_FILTER_INIT_NEXT'


export const LEA_PLATFORM_LAZY_FILTER_INIT = 'LEA_PLATFORM_LAZY_FILTER_INIT';
export const LEA_PLATFORM_LAZY_FILTER_INIT_SUCCESS = 'LEA_PLATFORM_LAZY_FILTER_INIT_SUCCESS';
export const LEA_PLATFORM_LAZY_FILTER_INIT_ERROR = 'LEA_PLATFORM_LAZY_FILTER_INIT_ERROR';
export const LEA_PLATFORM_LAZY_FILTER_INIT_NEXT = 'LEA_PLATFORM_LAZY_FILTER_INIT_NEXT'
// -------------------------------------------------------------------------------------------
export const IS_PUBLISHER_LAZY_FILTER_INIT = 'IS_PUBLISHER_LAZY_FILTER_INIT';
export const IS_PUBLISHER_LAZY_FILTER_INIT_SUCCESS = 'IS_PUBLISHER_LAZY_FILTER_INIT_SUCCESS';
export const IS_PUBLISHER_LAZY_FILTER_INIT_ERROR = 'IS_PUBLISHER_LAZY_FILTER_INIT_ERROR';
export const IS_PUBLISHER_LAZY_FILTER_INIT_NEXT = 'IS_PUBLISHER_LAZY_FILTER_INIT_NEXT'
export const IS_SUB_CHANNEL_LAZY_FILTER_INIT = 'IS_SUB_CHANNEL_LAZY_FILTER_INIT';
export const IS_SUB_CHANNEL_LAZY_FILTER_SUCCESS = 'IS_SUB_CHANNEL_LAZY_FILTER_SUCCESS';
export const IS_SUB_CHANNEL_LAZY_FILTER_ERROR = 'IS_SUB_CHANNEL_LAZY_FILTER_ERROR';
export const IS_SUB_CHANNEL_LAZY_FILTER_NEXT = 'IS_SUB_CHANNEL_LAZY_FILTER_NEXT';

// brand monitoring constant 

export const BM_PUBLISHER_LAZY_FILTER_INIT = 'BM_PUBLISHER_LAZY_FILTER_INIT';
export const BM_PUBLISHER_LAZY_FILTER_INIT_SUCCESS = 'BM_PUBLISHER_LAZY_FILTER_INIT_SUCCESS';
export const BM_PUBLISHER_LAZY_FILTER_INIT_ERROR = 'BM_PUBLISHER_LAZY_FILTER_INIT_ERROR';
export const BM_PUBLISHER_LAZY_FILTER_INIT_NEXT = 'BM_PUBLISHER_LAZY_FILTER_INIT_NEXT';

export const BM_CHANNEL_LAZY_FILTER_INIT = 'BM_CHANNEL_LAZY_FILTER_INIT';
export const BM_CHANNEL_LAZY_FILTER_INIT_SUCCESS = 'BM_CHANNEL_LAZY_FILTER_INIT_SUCCESS';
export const BM_CHANNEL_LAZY_FILTER_INIT_ERROR = 'BM_CHANNEL_LAZY_FILTER_INIT_ERROR';
export const BM_CHANNEL_LAZY_FILTER_INIT_NEXT = 'BM_CHANNEL_LAZY_FILTER_INIT_NEXT';

export const BM_SUB_CHANNEL_LAZY_FILTER_INIT = 'BM_SUB_CHANNEL_LAZY_FILTER_INIT';
export const BM_SUB_CHANNEL_LAZY_FILTER_INIT_SUCCESS = 'BM_SUB_CHANNEL_LAZY_FILTER_INIT_SUCCESS';
export const BM_SUB_CHANNEL_LAZY_FILTER_INIT_ERROR = 'BM_SUB_CHANNEL_LAZY_FILTER_INIT_ERROR';
export const BM_SUB_CHANNEL_LAZY_FILTER_INIT_NEXT = 'BM_SUB_CHANNEL_LAZY_FILTER_INIT_NEXT';
// ------------------------------------------------------------------

// -----------------------------------------------------------------------
// AM Filters:
// Destination
export const AM_DOMAIN_FILTER_INIT = 'AM_DOMAIN_FILTER_INIT';
export const AM_DOMAIN_FILTER_INIT_SUCCESS = 'AM_DOMAIN_FILTER_INIT_SUCCESS';
export const AM_DOMAIN_FILTER_INIT_ERROR = 'AM_DOMAIN_FILTER_INIT_ERROR';
export const AM_DOMAIN_FILTER_INIT_NEXT = 'AM_DOMAIN_FILTER_INIT_NEXT';

// Keyword
export const AM_KEYWORD_FILTER_INIT = 'AM_KEYWORD_FILTER_INIT';
export const AM_KEYWORD_FILTER_INIT_SUCCESS = 'AM_KEYWORD_FILTER_INIT_SUCCESS';
export const AM_KEYWORD_FILTER_INIT_ERROR = 'AM_KEYWORD_FILTER_INIT_ERROR';
export const AM_KEYWORD_FILTER_INIT_NEXT = 'AM_KEYWORD_FILTER_INIT_NEXT';

// Location
export const AM_LOCATION_FILTER_INIT = 'AM_LOCATION_FILTER_INIT';
export const AM_LOCATION_FILTER_INIT_SUCCESS = 'AM_LOCATION_FILTER_INIT_SUCCESS';
export const AM_LOCATION_FILTER_INIT_ERROR = 'AM_LOCATION_FILTER_INIT_ERROR';
export const AM_LOCATION_FILTER_INIT_NEXT = 'AM_LOCATION_FILTER_INIT_NEXT';

// Publisher
export const AM_PUBLISHER_FILTER_INIT = 'AM_PUBLISHER_FILTER_INIT';
export const AM_PUBLISHER_FILTER_INIT_SUCCESS = 'AM_PUBLISHER_FILTER_INIT_SUCCESS';
export const AM_PUBLISHER_FILTER_INIT_ERROR = 'AM_PUBLISHER_FILTER_INIT_ERROR';
export const AM_PUBLISHER_FILTER_INIT_NEXT = 'AM_PUBLISHER_FILTER_INIT_NEXT';

// Channel
export const AM_CHANNEL_FILTER_INIT = 'AM_CHANNEL_FILTER_INIT';
export const AM_CHANNEL_FILTER_INIT_SUCCESS = 'AM_CHANNEL_FILTER_INIT_SUCCESS';
export const AM_CHANNEL_FILTER_INIT_ERROR = 'AM_CHANNEL_FILTER_INIT_ERROR';
export const AM_CHANNEL_FILTER_INIT_NEXT = 'AM_CHANNEL_FILTER_INIT_NEXT';

// SubChannel
export const AM_SUB_CHANNEL_FILTER_INIT = 'AM_SUB_CHANNEL_FILTER_INIT';
export const AM_SUB_CHANNEL_FILTER_INIT_SUCCESS = 'AM_SUB_CHANNEL_FILTER_INIT_SUCCESS';
export const AM_SUB_CHANNEL_FILTER_INIT_ERROR = 'AM_SUB_CHANNEL_FILTER_INIT_ERROR';
export const AM_SUB_CHANNEL_FILTER_INIT_NEXT = 'AM_SUB_CHANNEL_FILTER_INIT_NEXT';